import React from 'react'
import {ArrowRoundedDown12x7Svg, CategorySVG} from '../../svg'
import {FormattedMessage} from 'react-intl'
import {url} from "../../services/utils";
import dynamic from 'next/dynamic'
import {IItem} from "../../types/header";
import cls from "./mobile.module.scss"
import {useRouter} from "next/router";

const Collapse = dynamic(() => import('../shared/Collapse'))

interface IProps {
    level?: number,
    links?: Array<{
        children?: Array<IItem>,
        childs?: Array<IItem>,
        label?: JSX.Element,
        type?: string,
        name?: string,
        id?: number,
        url_key?: string,
        custom_url?: string,
        page_id?: number,
        category_icon_path?: string,
        slug?: string,
    }>
    onItemClick: (item?: { data?: { type: string; locale: string; }; type?: string; }) => void
}

function MobileLinks(props: IProps) {
    const {links, level = 1, onItemClick} = props
    const router = useRouter();
    const handleItemClick = (item?: { data?: { type: string; locale: string; }; type?: string; }) => {
        if (onItemClick) {
            onItemClick(item)
        }
    }
    let slashWithLocale = router.locale !== "catchAll" ? `/${router.locale}` : ""
    const linksList = links && links.map((link, index) => {
        let item = (
            <Collapse
                // @ts-ignore
                toggleClass={cls["mobile-links__item--open"]}
                // @ts-ignore
                render={({toggle, setItemRef, setContentRef}) => {
                    let arrow
                    let subLinks
                    let linkOrButton
                    if (link.type !== 'header' && link.type !== 'footer') link.type = 'link'
                    if (
                        (link.childs && link.childs.length > 0) ||
                        (link.children && link.children.length > 0)
                    ) {
                        arrow = (
                            <button
                                className={cls["mobile-links__item-toggle"]}
                                type="button"
                                onClick={toggle}
                            >
                                <ArrowRoundedDown12x7Svg className={cls["mobile-links__item-arrow"]}/>
                            </button>
                        )

                        subLinks = (
                            <div className={cls["mobile-links__item-sub-links"]} ref={setContentRef}>
                                <MobileLinks
                                    links={link.childs || link.children}
                                    // level={level && level + 1}
                                    level={level + 1}
                                    onItemClick={onItemClick}
                                />
                            </div>
                        )
                    }

                    if (link.type === 'button') {
                        linkOrButton = (
                            <button
                                type="button"
                                className={cls["mobile-links__item-link"]}
                                onClick={() => {
                                    handleItemClick(link)
                                }}
                            >
                                {link.name || link.label}
                            </button>
                        )
                    } else {
                        let href
                        if (link.type === 'header' && link?.id || link.type === 'footer' && link?.id) {
                            href = link.url_key
                                ? '/page/' + link?.url_key
                                : link?.custom_url
                                    ? '/page/' + link?.custom_url
                                    : link?.page_id ? '/page/' + link?.page_id : ''
                        } else if (link?.slug) {
                            href = `${url.category(link, slashWithLocale)}`
                        } else {
                            href = link.url_key
                                ? '/' + link.url_key
                                : link.custom_url
                                    ? '/' + link.custom_url
                                    : link?.page_id ? '/page/' + link?.page_id : ''
                        }

                        linkOrButton = (
                            <>

                                {link?.label?.props.id != 'categoies' ?
                                    (
                                        <>
                                            <a
                                                href={href}
                                                className={cls["mobile-links__item-link"]}
                                                onClick={() => {
                                                    // alert(link)
                                                    handleItemClick(link)
                                                    toggle()
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className={cls["mobile-links__item-link"]}
                                                    onClick={() => {
                                                        handleItemClick(link)
                                                        toggle()
                                                    }}
                                                >
                                                    {link.name}{' '}
                                                </button>
                                            </a>
                                        </>
                                    )
                                    : (
                                        <span className={cls["link-label-fms"]} onClick={toggle}>
                                            <CategorySVG/>
                                            <FormattedMessage
                                                id="Menu.category"
                                                defaultMessage="Category"
                                            />
                                        </span>
                                    )
                                }
                            </>
                        )
                    }

                    return (
                        <div
                            className={`${cls["mobile-links__item"]} ${link.id === 1 && cls["mobile-links__item--open"]}`}
                            ref={setItemRef}>
                            {
                                link.id === 1
                                    ? <></>
                                    : (
                                        <div className={cls["mobile-links__item-title"]}>
                                            {linkOrButton}
                                            {arrow}
                                        </div>
                                    )
                            }
                            {subLinks}
                        </div>
                    )
                }}
            />
        )

        return <li key={index}>{item}</li>
    })

    return (
        <ul className={`${cls["mobile-links"]} ${cls[`mobile-links--level--${level}`]}`}>
            {linksList}
        </ul>
    )
}

export default MobileLinks;
